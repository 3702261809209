<template>
  <div class="top">
    <div>
      <img src="@/static/topCard/CaretCircleLeft.png"
           v-if="showBack"
           @click="back"
           alt="">
    </div>
    <div class="tab">
      {{text}}
    </div>
    <div class="right_slot">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";

const router = useRouter()

const { text, showBack } = defineProps({
  text: {
    type: String,
    default: ''
  },
  showBack: {
    type: Boolean,
    default: true
  }
})

const back = () => {
  router.go(-1)
  // debugger
}
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  display: flex;
  // width: 100vw;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100rem;
  // margin-right: 100rem;
  img {
    position: absolute;
    width: 50rem;
    height: 50rem;
    top: 25rem;
    left: 25rem;
    cursor: pointer;
  }
  .tab {
    width: 260rem;
    height: 54rem;
    border-radius: 30rem;
    background: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24rem;
    font-weight: 800;
    color: #ffffff;
  }
  .right_slot {
  }
}
</style>