<template>
  <div>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             title="相关视频"
             :footer="null"
             width="650rem">
      <div class="center_table">
        <div class="table_head">
          <div>
            视频名
          </div>

          <div>
            操作
          </div>
        </div>
      </div>
      <div class="table_line"
           v-for="(item,index) in list"
           :key="index">
        <div class="two">
          <div>
            {{item.filename}}
          </div>
          <div>
            <a-button type="primary"
                      @click="toVideo(item)">
              播放视频
            </a-button>
          </div>
        </div>

      </div>
    </a-modal>
  </div>
</template>


<script setup>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
const router = useRouter()
let dialogVisible = ref(false)
let list = ref([])


const toVideo = (item) => {
  dialogVisible.value = false
  router.push('/course/preview?type=video&url=' + item.url + "&knowledge_desc=" + item.knowledge_desc + '&filename=' + item.filename)

}


defineExpose({
  dialogVisible, list
})

</script>

<style lang="scss" scoped>
.table_head {
  height: 40rem;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18rem;
    color: #666660;
    &:nth-child(1) {
      flex: 3;
      text-indent: 70px;
    }
    &:nth-child(2) {
      flex: 1;
      display: inline-block;
      text-align: center;
    }
  }
}
.table_line {
  background: white;
  border-bottom: 1px solid #eaeaea;
  font-size: 18rem;
  line-height: 40rem;
  .two {
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    div {
      &:nth-child(1) {
        flex: 3;
        text-indent: 70px;
        font-weight: bold;
      }
      &:nth-child(2) {
        flex: 1;
        display: inline-block;
        text-align: center;
      }
    }
  }
}
</style>
