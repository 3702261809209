<template>
  <div class="wrap">
    <div id="jsmind_container"
         ref="container"></div>
    <div class="recover_btn"
         @click="resizeMap">
      <span style="color:#999;font-size:16rem;padding-right:8rem">（点击节点显示对应内容；鼠标滚轮放大缩小）</span>
      <div class="img">
        <img src="@/static/error/复位.png"
             alt=""
             title="复位">
      </div>
    </div>
  </div>
</template>

<script setup>
import 'jsmind/style/jsmind.css'
import '@/assets/css/jsmind.css'
import jsMind from 'jsmind/js/jsmind.js'
// require('jsmind/js/jsmind.draggable.js')
require('jsmind/js/jsmind.screenshot.js')
import { nextTick, onMounted, ref, watch } from 'vue'

let mind = ref({
  /* 元数据，定义思维导图的名称、作者、版本等信息 */
  meta: {
    name: '思维导图',
    author: '',
    version: ''
  },
  /* 数据格式声明 */
  format: 'node_tree',
  /* 数据内容 */
  data: {}
})
let options = {
  container: 'jsmind_container', // [必选] 容器的ID
  editable: false, // [可选] 是否启用编辑
  theme: 'greensea', // [可选] 主题
  view: {
    engine: 'canvas', // 思维导图各节点之间线条的绘制引擎
    hmargin: 20, // 思维导图距容器外框的最小水平距离
    vmargin: 50, // 思维导图距容器外框的最小垂直距离
    line_width: 2, // 思维导图线条的粗细
    line_color: '#2196F3' // 思维导图线条的颜色
  },
  layout: {
    hspace: 40, // 节点之间的水平间距
    vspace: 40, // 节点之间的垂直间距
    pspace: 0 // 节点与连接线之间的水平间距（用于容纳节点收缩/展开控制器）
  },
  shortcut: {
    enable: false // 是否启用快捷键 默认为true
  },
}
let e_editor = undefined
let jm = undefined
let thisNode = ref(undefined)
let nodeId = undefined

let originalScrollLeft = ref(0)
let originalScrollTop = ref(0)
const init = (data) => {
  mind.value.data = data
  jm.show(mind.value);//显示
  mouseDrag()
  mouseWheel()
  setZoom()
  e_editor = jm.view.e_editor
  getNode()
  const el = document.querySelector('.jsmind-inner')
  originalScrollLeft.value = el.scrollLeft
  originalScrollTop.value = el.scrollTop
  // console.log(originalScrollLeft.vlaue)
}
onMounted(() => {
  nextTick(() => {
    jm = new jsMind(options)
    // init()
  })
})

// 关闭弹窗时取消选择
const cancelSelected = () => {
  jm.select_clear()
  thisNode.value = {}
}
// 
const getNode = () => {
  // jsmind 添加自定义点击事件
  jm.view.add_event(e_editor, 'click', (e) => {
    if (jm.get_selected_node()) {
      thisNode.value = jm.get_selected_node()
    } else if (thisNode.value && thisNode.value.id) {
      jm.select_node(thisNode.value.id)
    }
    if (thisNode.value && thisNode.value.id) {
      emits('setFloatData', thisNode.value.id, thisNode.value.data.parentId, thisNode.value.data.type)
    }
  })
}
const emits = defineEmits(['setFloatData'])

let container = ref()
// 鼠标滚轮放大缩小
const mouseWheel = () => {
  if (document.addEventListener) {
    // document.addEventListener('DOMMouseScroll', scrollFunc, false)
    container.value.addEventListener('DOMMouseScroll', scrollFunc, false)
  }
  container.value.onmousewheel = scrollFunc
}
// 滚轮缩放
const scrollFunc = (e) => {
  e = e || window.event
  e.preventDefault()
  if (e.wheelDelta) {
    if (e.wheelDelta > 0) {
      jm.view.zoomIn()
    } else {
      jm.view.zoomOut()
    }
  } else if (e.detail) {
    if (e.detail > 0) {
      jm.view.zoomOut()
    } else {
      jm.view.zoomIn()
    }
  }
  jm.resize()

}

let dragNodeFlag = ref(false)
// 鼠标拖拽
const mouseDrag = () => {
  // 里层
  const el = document.querySelector('.jsmind-inner')
  // 选中节点
  let selected

  el.onmousedown = (ev) => {
    // 选中节点
    selected = jm.get_selected_node()
    // 标识 是否拖拽节点 避免冲突
    dragNodeFlag.value = !!selected

    const disX = ev.clientX
    const disY = ev.clientY
    const originalScrollLeft = el.scrollLeft
    const originalScrollTop = el.scrollTop
    const originalScrollBehavior = el.style['scroll-behavior']
    const originalPointerEvents = el.style['pointer-events']
    // auto: 默认值，表示滚动框立即滚动到指定位置。
    el.style['scroll-behavior'] = 'auto'
    // 鼠标移动事件是监听的整个document，这样可以使鼠标能够在元素外部移动的时候也能实现拖动
    document.onmousemove = (ev) => {
      if (dragNodeFlag.value) return
      //  drag= false
      ev.preventDefault()
      // 计算拖拽的偏移距离
      const distanceX = ev.clientX - disX
      const distanceY = ev.clientY - disY

      el.scrollTo(originalScrollLeft - distanceX, originalScrollTop - distanceY)

      // 在鼠标拖动的时候将点击事件屏蔽掉
      el.style['pointer-events'] = 'none'
      el.style.cursor = 'grabbing'
    }
    document.onmouseup = () => {
      if (!dragNodeFlag.value) {
        el.style['scroll-behavior'] = originalScrollBehavior
        el.style['pointer-events'] = originalPointerEvents
        el.style.cursor = 'grab'
        if (thisNode.value && thisNode.value.id) {
          jm.select_node(thisNode.value.id)
        }
      }
      document.onmousemove = document.onmouseup = null
    }
  }
}
// 重写设计层级方法
const setZoom = () => {
  jm.view.setZoom = (zoom) => {
    if ((zoom < jm.view.minZoom) || (zoom > jm.view.maxZoom)) {
      return false
    }
    jm.view.actualZoom = zoom;
    for (var i = 0; i < jm.view.e_panel.children.length; i++) {
      jm.view.e_panel.children[i].style.transformOrigin = '0px 0px'
      // jm.view.e_panel.children[i].style.zoom = zoom //火狐浏览器zoom属性不起作用
      jm.view.e_panel.children[i].style.transform = "scale(" + zoom + ")";
    }
    return true
  }
}

// 还原大小位置
const resizeMap = () => {
  // console.log(jm.view)
  for (var i = 0; i < jm.view.e_panel.children.length; i++) {
    jm.view.e_panel.children[i].style.transformOrigin = '0px 0px'
    jm.view.e_panel.children[i].style.transform = 'scale(' + 1 + ')';
    jm.view.actualZoom = 1;
    // jm.view.e_panel.children[i].style.zoom = 1;
  }
  const el = document.querySelector('.jsmind-inner')
  el.scrollTo(originalScrollLeft, el.scrollTop)
}
defineExpose({
  init, cancelSelected
})


</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  .recover_btn {
    position: absolute;
    top: 24px;
    right: 40px;
    .img {
      display: inline-block;
      cursor: pointer;
      width: 32rem;
      height: 32rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
#jsmind_container {
  width: 98%;
  padding-top: 80rem;
  height: calc(100vh - 100rem);
  // height: 100%;
}
</style>