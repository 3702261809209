import { instance } from '@/utils/http.js'

// 收藏本-列表
export const getList = (params) => {
  return instance({
    url: '/api/v1/problems/favorite_lst',
    method: 'get',
    params
  })
}

// 错题本收藏-删除
export const delRow = (data) => {
  return instance({
    url: '/api/v1/problems/dels',
    method: 'post',
    data
  })
}

// 获取收藏题目详情
export const getInfo = (params) => {
  return instance({
    url: '/api/v1/problems/show',
    method: 'get',
    params
  })
}

// 修改收藏本笔记
export const editBiji = (data) => {
  return instance({
    url: '/api/v1/problems/edit',
    method: 'post',
    data
  })
}
// 修改错题本笔记
export const editErrorBiji = (data) => {
  return instance({
    url: '/api/v1/my/comment',
    method: 'post',
    data
  })
}


// 获取错误题目科目列表
export const getErrorSubject = () => {
  return instance({
    url: '/api/v1/my/wrong_question_subject',
    method: 'get'
  })
}

// 错题列表
export const getErrorList = (params) => {
  return instance({
    url: '/api/v1/my/wrong_topic',
    method: 'get',
    params
  })
}

// 错题详情
export const getErrorInfo = (params) => {
  return instance({
    url: '/api/v1/my/wrong_topic_detail',
    method: 'get',
    params
  })
}
//导出错题
export const exportWrongPrint = function (params) {
  return instance({
    url: '/api/v1/my/wrong_print',
    method: 'get',
    responseType: "blob",
    params
  })
}

// 知识点相关
export const getKnowledgeInfo = (params) => {
  return instance({
    url: '/api/v1/my/knowledge',
    method: 'get',
    params
  })
}

//根据题目id删除错题
export const delError = (data) => {
  return instance({
    url: '/api/v1/my/wrong_topic_del',
    method: 'post',
    data
  })
}

// 错题题型
export const errorTopicType = (params) => {
  return instance({
    url: 'api/v1/my/wrong_topic_question_type',
    method: 'get',
    params
  })
}
