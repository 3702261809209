<template>
  <div v-drag>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             title="修改笔记"
             :closable="false"
             destroyOnClose
             @cancel="cancel"
             @ok="create"
             width="400px">
      <a-textarea placeholder="请输入"
                  v-if='route.query.type==1'
                  v-model:value="form.comment_to_user" />
      <a-textarea placeholder="请输入"
                  v-else
                  v-model:value="form.favorite_tags" />
      <template #footer>
        <div class="btn_warp">
          <div @click="cancel">取消</div>
          <div @click="create">确定</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { editBiji, editErrorBiji } from '@/api/errorBook.js'
import { message } from "ant-design-vue"
import { useRoute } from 'vue-router'
let route = useRoute()
const emit = defineEmits(['updateInfo'])

let form = reactive({})
let dialogVisible = ref(false)


const cancel = () => {
  dialogVisible.value = false
}


const create = async () => {
  if (route.query.type == 1) {
    let data = {
      user_paper_detail_id: form.user_paper_detail_id,
      comment_to_user: form.comment_to_user
    }
    await editErrorBiji(JSON.parse(JSON.stringify(data)))
    message.success("修改成功")
    emit('updateInfo')
    cancel()
  } else {
    let data = {
      id: form.question_favorite_id,
      tags: form.favorite_tags
    }
    await editBiji(JSON.parse(JSON.stringify(data)))
    message.success("修改成功")
    emit('updateInfo')
    cancel()
  }


}

const setData = (data) => {
  for (const key in data) {
    form[key] = data[key]
  }
  dialogVisible.value = true
}

defineExpose({
  setData
})


</script>

<style lang="scss" scoped>
.btn_warp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  div {
    flex: 1;
    font-weight: bold;
    font-size: 24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(2) {
      color: #2196f3;
      border-left: 1px solid #e5e5e5;
    }
  }
}
</style>